.question-checkbox {
  display: block !important;
}

.question-checkbox:not(:last-child) {
  margin-bottom: 1em;
}

.question-checkbox label {
  max-width: 50%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
