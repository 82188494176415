.stages-bar-cell-popup {
  z-index: 0 !important;

  animation: appear 1s ease-in-out;
}

.ui.label.button.active-cell {
  box-shadow: 0 0 0 2px #2185d0 !important;
  /* Could consider animating the box-shadow, see: https://tobiasahlin.com/blog/how-to-animate-box-shadow/ */
}

@keyframes appear {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
