body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  background: rgb(219, 225, 216);
  background: linear-gradient(
      180deg,
      rgba(44, 112, 25, 0.3) 0%,
      #efefef 75%,
      #efefef 100%
    )
    fixed;
}
