.performance-row:hover {
  cursor: pointer;
  background: rgba(0, 0, 0, 0.05);
}

.performance-header {
  display: flex;
}

.performance-header .current-performance {
  flex-grow: 1;
  text-align: center;
}

.performance-index {
  color: rgba(0, 0, 0, 0.6);
  font-weight: normal;
  font-size: 1.2rem;
}

.answer-list__answer:hover {
  cursor: pointer;
}

.accordion.answered-questions .title {
  display: flex;
}

.accordion.answered-questions .title .icon {
  width: auto !important;
  margin-right: 1em !important;
}

.accordion.answered-questions .answer-title__timestamp {
  flex-shrink: 0;
  white-space: nowrap;
}

.accordion.answered-questions .answer-title__result {
  margin-left: auto;
  flex-shrink: 0;
  white-space: nowrap;
}

.accordion.answered-questions .answer-title__question-text {
  flex-shrink: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 1em;
}

.answers-filter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}
