.image-dropzone {
  border: 1px dashed darkgray;
  border-radius: 5px;
  padding: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-preview__image {
  max-height: 50vh;
}

.image-preview__container {
  margin-bottom: 10px;
  padding-bottom: calc(
    1em + 30px
  ) !important; /* Hack: add height of bottom-attached label */
}
