.performance-video--strict {
  pointer-events: none;
}

.video-cover {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
}
