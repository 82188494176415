#app-version {
  position: fixed;
  bottom: 5px;
  right: 5px;
  font-style: italic;
  opacity: 0.5;
}

body,
html,
#root {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
}

main {
  flex: 1 0 auto;
}

footer {
  flex-shrink: 0;
}

body {
  padding-top: calc(52px + 1em) !important;
}
