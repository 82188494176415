.messages-container {
  position: fixed;
  z-index: 9999;
  top: calc(52px + 2em);
  right: 0;
  left: 0;
  margin-right: auto;
  margin-left: auto;
  width: 300px;
}

.messages-container .message .content {
  margin-right: 10px;
}
